












































































































































































































































































































































@import '~@/assets/styles/helpers/_variables.scss';

.text-name {
  display: inline-block;
  line-height: 0.9;

  @media (min-width: 420px) {
    width: 115px!important;
  } 
}

.cadastro-usuario >>> .v-data-table__wrapper table {
  min-width: auto;
}

.box-table::v-deep {

  table {
    min-width: 400px;
  }

  .cancelado {
    color: #F00;
  }

  .mobile {
    @media (max-width: 420px) {
      display: none;
    } 
  }

  .v-btn {
    height: 20px !important;

  }
}

.list {
  padding-left: 0;
  &-item {
    list-style: none;
    display: flex;
    align-items: center;
    &:nth-child(1) {
      .list-item-circle {
        background-color: $colorSuccess;
      }
    }
    &:nth-child(2) {
      .list-item-circle {
        background-color: $colorWarning;
      }
    }
    &:nth-child(3) {
      .list-item-circle {
        background-color: $colorError;
      }
    }
    &-circle {
      display: inline-block;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      margin-right: 10px;
      background-color: $colorSuccess;
    }
  }
}

